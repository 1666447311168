import dashboard from "./dashboard.json"
import billingManagement from "./billing-management"
import userManagement from "./user-management.json"
import notification from "./notification-management.json"
import error from "./error.json"
import menu from "./menu.json"
import message from "./message.json"
import required from "./required.json"
import profile from "./user-profile.json"
import authentication from "./authentication.json"
import permissons from "./permissons.json"
import gradeManagement from "./grade-management.json"
import student from "./student-management.json"
import teacher from "./teacher-management.json"
import billing from "./billing-management.json"
import classManagement from "./class-management.json"
import report from "./report-management.json"
import salary from "./salary-management.json"
import school from "./school-management.json"
import system from "./system.json"
import chat from "./chat.json"
import attendance from "./attendance-management.json"
import teachingReport from "./teaching-report.json"
import targetGroup from "./target-group.json"
import teachingReportSetting from "./teaching-report-setting.json"
import classCalendar from "./class-calendar.json"
import classExportStdSchedule from "./class-export-std-schedule.json"

const translate = {
  ID: "ID",
  Email: "電子メール",
  Password: "パスワード",
  "Student List": "学生名簿",
  "Client Name": "クライアント名",
  Packages: "Packages",
  "Created at": "広告を作成する",
  "View details": "詳細を見る",
  "Forgot password Forgot": "パスワードをお忘れですか",
  "Remember login": "ログインを記憶する",
  "Log in your account": "アカウントにログインします",
  Logout: "ログアウト",
  "Choose multiple option": "選択可",
  "Edit image": "画像を編集",
  "File size exceeds the allowed limit 2 MB": "ファイルサイズが許可された2MBを超えています",
  "This file doesn't support.": "このファイルはサポートされていません",
  "Powered by": "パワード・バイ",
  Verify: "確認",
  Enable: "有効化",
  Disable: "無効化",
  "Notification Detail": "通知の詳細",
  "attachment files": "添付ファイル",
  s: "秒",
  m: "分",
  h: "時",
  d: "日",
  ago: "前",
  Done: "完了",
  "Picture here": "画像はこちら",
  "Supported formats: png, jpg": "サポートされている形式: png、jpg",
  "Custom domain setting": "カスタムドメイン設定",
  "This page is comming soon": "このページは近日公開予定です",
  "This page is not unknown or does not exsit": "このページは不明ではないか、存在しません",
  "Sorry about that, but the page you looking for is not available":
    "申し訳ありませんが、お探しのページは見つかりませんでした",
  "Service unavailable": "サービスが利用できません",
  "This server is temporary busy, try again later":
    "このサーバーは一時的に混雑しています。後でもう一度お試しください",
  Picture: "写真",
  "Address 1": "住所 1",
  "Address 2": "住所 2",
  email_support_refund:
    "あなたに返金いたします。サポートが必要な場合は {{email}} にお問い合わせください。",
  email_support_bank_tranfer: "xxx {{test}} 123",
  email_support_package_limit: "xxx {{test}} 123",
  email_support_package_upgrade: "xxx {{test}} 123",
  "List of user accounts managed by Super admin":
    "スーパー管理者が管理するユーザーアカウントの一覧",
  "after the street number": "番地の後",
  "to town": "街へ",
  "e.g. Tokyo-to Shibuya-ku Sakuragaoka-cho": "例：東京都渋谷区桜丘町",
  "e.g. 1-2-3 ABC Building, 4th Floor": "例：1-2-3 ABCビル4階",
  "Choose an official school": "学校",
  "Drag and drop CSV files here": "ここにCSVファイルをドラッグアンドドロップしてください",
  "Select file": "ファイルを選択",
  SaveAndContinue: "保存＆続行",
  ...dashboard,
  ...billingManagement,
  ...userManagement,
  ...notification,
  ...error,
  ...menu,
  ...message,
  ...required,
  ...profile,
  ...authentication,
  ...permissons,
  ...gradeManagement,
  ...student,
  ...teacher,
  ...billing,
  ...classManagement,
  ...report,
  ...salary,
  ...school,
  ...system,
  ...chat,
  ...attendance,
  ...targetGroup,
  ...teachingReport,
  ...teachingReportSetting,
  ...classCalendar,
  ...classExportStdSchedule
}

export default translate
