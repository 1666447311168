import { Button, ConfigProvider } from "antd"
import { hexToRgba } from "lib/function"

export default function ButtonSecondary({ name, children, rows, ...rest }) {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: `#E3F1F8`,
              defaultColor: `#4DABD1`,
              colorBorder: `E3F1F8`,
              defaultBg: `#E3F1F8`,
              defaultHoverBg: hexToRgba("#E3F1F8", 0.6),
              defaultActiveBg: "#9fd6f2",
              // lineWidth: 0,

              fontSize: 16,
              controlHeightSM: 28,
              controlHeight: 40,
              controlHeightLG: 48,
              display: "flex",
              alignItems: "center",
              
            }
          }
        }}
      >
        <Button className="!text-[#4DABD1] hover:!text-white !font-bold" disabled={rows?.length === 0} {...rest}>{children}</Button>
      </ConfigProvider>
    </div>
  )
}
