import { getBaseDataLocal } from "lib/localstorage";

export const GENDER = {
  MALE: 0,
  FEMALE: 1,
  OTHER: 2,
};
export const CONTACT_STATUS = {
  CONTACTED: 0,
  NO_CONTACTED: 1,
};

export const INQUIRY_MEDIA_MAIN = {
  HOME_PAGE: 0,
  NEWSPAPER_INSERT_FLYER: 1,
  GATEKEEPER_FLYER: 2,
  POSTING: 3,
  INTRODUCTION_REFERRAL: 4,
  SIBLING: 5,
  SIGNBOARD: 6,
  JUKU_SHIRU: 7,
  JUKU_NAVI_WEBSITE: 8,
  JUKU_NAVI_PHONE: 9,
  OTHERS: 10,
};

export const INQUIRY_MEDIA_SUB = {
  HOME_PAGE: 0,
  NEWSPAPER_INSERT_FLYER: 1,
  GATEKEEPER_FLYER: 2,
  POSTING: 3,
  INTRODUCTION_REFERRAL: 4,
  SIBLING: 5,
  SIGNBOARD: 6,
  JUKU_SHIRU: 7,
  JUKU_NAVI_WEBSITE: 8,
  JUKU_NAVI_PHONE: 9,
  OTHERS: 10,
};

export const STUDENT_REGISTRATION_STATUS = {
  INQUIRY_DROP: 0,
  PENDING: 1,
  PRE_CAMP: 2,
  WAITING_FOR_VISIT: 3,
  CONTRACT: 4,
};

export const HOLD_REASON = {
  FINANCIAL_REASONS: 0,
  SCHEDULE: 1,
  COMPARISON_WITH_OTHER_COMPANIES: 2,
  CRAM_SCHOOL_ENVIRONMENT: 3,
  FAILED_EXPERIENCE: 4,
  TEACHERS_GENDER: 5,
  SOCIAL_STANDING: 6,
  MOTIVATION: 7,
  SERVICE_CONTENT: 8,
  LESSONS_CLUB_ACTIVITIES: 9,
  SOMEONE_I_DONT_GET_ALONG_WITH: 10,
  NO_ACQUAINTANCES: 11,
  INCONSISTENT_TEACHING_METHOD: 12,
  RESPONSE_FROM_THE_CLASSROOM_MANAGER: 13,
  COMMUNICATION_ISSUES: 14,
  CRAM_SCHOOL_IS_UNNECESSARY: 15,
  CHANGE_OF_COURSE: 16,
  MAY_MOVE_IN_THE_NEAR_FUTURE: 17,
  OTHERS: 18,
};

export function listHoldReason() {
  return [
    { value: HOLD_REASON.FINANCIAL_REASONS, label: "Financial reasons" },
    { value: HOLD_REASON.SCHEDULE, label: "Schedule" },
    {
      value: HOLD_REASON.COMPARISON_WITH_OTHER_COMPANIES,
      label: "Comparison with other companies",
    },
    { value: HOLD_REASON.CRAM_SCHOOL_ENVIRONMENT, label: "Cram school environment" },
    { value: HOLD_REASON.FAILED_EXPERIENCE, label: "Failed experience" },
    { value: HOLD_REASON.TEACHERS_GENDER, label: "Teacher's gender" },
    { value: HOLD_REASON.SOCIAL_STANDING, label: "Social standing" },
    { value: HOLD_REASON.MOTIVATION, label: "Motivation" },
    { value: HOLD_REASON.SERVICE_CONTENT, label: "Service content" },
    { value: HOLD_REASON.LESSONS_CLUB_ACTIVITIES, label: "Lessons / Club activities" },
    { value: HOLD_REASON.SOMEONE_I_DONT_GET_ALONG_WITH, label: "Someone I don't get along with" },
    { value: HOLD_REASON.NO_ACQUAINTANCES, label: "No acquaintances" },
    { value: HOLD_REASON.INCONSISTENT_TEACHING_METHOD, label: "Inconsistent teaching method" },
    {
      value: HOLD_REASON.RESPONSE_FROM_THE_CLASSROOM_MANAGER,
      label: "Response from the classroom manager",
    },
    { value: HOLD_REASON.COMMUNICATION_ISSUES, label: "Communication issues" },
    { value: HOLD_REASON.CRAM_SCHOOL_IS_UNNECESSARY, label: "Cram school is unnecessary" },
    { value: HOLD_REASON.CHANGE_OF_COURSE, label: "Change of course" },
    { value: HOLD_REASON.MAY_MOVE_IN_THE_NEAR_FUTURE, label: "May move in the near future" },
    { value: HOLD_REASON.OTHERS, label: "Others" },
  ];
}

export function listGender() {
  const { enums } = getBaseDataLocal();
  const list = Object.keys(enums?.gender_type);

  let _arr = [];
  list.forEach((v) => {
    switch (Number(v)) {
      case GENDER.MALE:
        _arr.push({ value: GENDER.MALE, label: "Male" });
        break;
      case GENDER.FEMALE:
        _arr.push({ value: GENDER.FEMALE, label: "Female" });
        break;
      case GENDER.OTHER:
        _arr.push({ value: GENDER.OTHER, label: "Type Other" });
        break;
      default:
        break;
    }
  });
  return _arr;
}

export function listContactStatus() {
  const { enums } = getBaseDataLocal();
  const list = Object.keys(enums?.gender_type);

  let _arr = [];
  list.forEach((v) => {
    switch (Number(v)) {
      case GENDER.MALE:
        _arr.push({ value: CONTACT_STATUS.CONTACTED, label: "Contacted" });
        break;
      case GENDER.FEMALE:
        _arr.push({ value: CONTACT_STATUS.NO_CONTACTED, label: "Not contacted" });
        break;
      default:
        break;
    }
  });
  return _arr;
}
export function listInquiryMediaMain() {
  const { enums } = getBaseDataLocal();
  // const list = Object.keys(enums?.inquiry_media_main)
  let _arr = [];
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((v) => {
    switch (Number(v)) {
      case INQUIRY_MEDIA_MAIN.HOME_PAGE:
        _arr.push({ value: INQUIRY_MEDIA_MAIN.HOME_PAGE, label: "Homepage" });
        break;
      case INQUIRY_MEDIA_MAIN.NEWSPAPER_INSERT_FLYER:
        _arr.push({
          value: INQUIRY_MEDIA_MAIN.NEWSPAPER_INSERT_FLYER,
          label: "Newspaper insert flyer",
        });
        break;
      case INQUIRY_MEDIA_MAIN.GATEKEEPER_FLYER:
        _arr.push({ value: INQUIRY_MEDIA_MAIN.GATEKEEPER_FLYER, label: "Gatekeeper flyer" });
        break;
      case INQUIRY_MEDIA_MAIN.POSTING:
        _arr.push({ value: INQUIRY_MEDIA_MAIN.POSTING, label: "Posting" });
        break;
      case INQUIRY_MEDIA_MAIN.INTRODUCTION_REFERRAL:
        _arr.push({
          value: INQUIRY_MEDIA_MAIN.INTRODUCTION_REFERRAL,
          label: "Introduction/Referral",
        });
        break;
      case INQUIRY_MEDIA_MAIN.SIBLING:
        _arr.push({ value: INQUIRY_MEDIA_MAIN.SIBLING, label: "Sibling Registration" });
        break;
      case INQUIRY_MEDIA_MAIN.SIGNBOARD:
        _arr.push({ value: INQUIRY_MEDIA_MAIN.SIGNBOARD, label: "Signboard" });
        break;
      case INQUIRY_MEDIA_MAIN.JUKU_SHIRU:
        _arr.push({ value: INQUIRY_MEDIA_MAIN.JUKU_SHIRU, label: "Juku Shiru" });
        break;
      case INQUIRY_MEDIA_MAIN.JUKU_NAVI_WEBSITE:
        _arr.push({ value: INQUIRY_MEDIA_MAIN.JUKU_NAVI_WEBSITE, label: "Juku Navi (Website)" });
        break;
      case INQUIRY_MEDIA_MAIN.JUKU_NAVI_PHONE:
        _arr.push({ value: INQUIRY_MEDIA_MAIN.JUKU_NAVI_PHONE, label: "Juku Navi (Phone)" });
        break;
      case INQUIRY_MEDIA_MAIN.OTHERS:
        _arr.push({ value: INQUIRY_MEDIA_MAIN.OTHERS, label: "Others" });
        break;
      default:
        break;
    }
  });

  return _arr;
}
export function listInquiryMediaSub() {
  const { enums } = getBaseDataLocal();
  // const list = Object.keys(enums?.inquiry_media_sub)

  let _arr = [];
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((v) => {
    switch (Number(v)) {
      case INQUIRY_MEDIA_SUB.HOME_PAGE:
        _arr.push({ value: INQUIRY_MEDIA_SUB.HOME_PAGE, label: "Homepage" });
        break;
      case INQUIRY_MEDIA_SUB.NEWSPAPER_INSERT_FLYER:
        _arr.push({
          value: INQUIRY_MEDIA_SUB.NEWSPAPER_INSERT_FLYER,
          label: "Newspaper insert flyer",
        });
        break;
      case INQUIRY_MEDIA_SUB.GATEKEEPER_FLYER:
        _arr.push({ value: INQUIRY_MEDIA_SUB.GATEKEEPER_FLYER, label: "Gatekeeper flyer" });
        break;
      case INQUIRY_MEDIA_SUB.POSTING:
        _arr.push({ value: INQUIRY_MEDIA_SUB.POSTING, label: "Posting" });
        break;
      case INQUIRY_MEDIA_SUB.INTRODUCTION_REFERRAL:
        _arr.push({
          value: INQUIRY_MEDIA_SUB.INTRODUCTION_REFERRAL,
          label: "Introduction/Referral",
        });
        break;
      case INQUIRY_MEDIA_SUB.SIBLING:
        _arr.push({ value: INQUIRY_MEDIA_SUB.SIBLING, label: "Sibling Registration" });
        break;
      case INQUIRY_MEDIA_SUB.SIGNBOARD:
        _arr.push({ value: INQUIRY_MEDIA_SUB.SIGNBOARD, label: "Signboard" });
        break;
      case INQUIRY_MEDIA_SUB.JUKU_SHIRU:
        _arr.push({ value: INQUIRY_MEDIA_SUB.JUKU_SHIRU, label: "Juku Shiru" });
        break;
      case INQUIRY_MEDIA_SUB.JUKU_NAVI_WEBSITE:
        _arr.push({ value: INQUIRY_MEDIA_SUB.JUKU_NAVI_WEBSITE, label: "Juku Navi (Website)" });
        break;
      case INQUIRY_MEDIA_SUB.JUKU_NAVI_PHONE:
        _arr.push({ value: INQUIRY_MEDIA_SUB.JUKU_NAVI_PHONE, label: "Juku Navi (Phone)" });
        break;
      case INQUIRY_MEDIA_SUB.OTHERS:
        _arr.push({ value: INQUIRY_MEDIA_SUB.OTHERS, label: "Others" });
        break;
      default:
        break;
    }
  });

  return _arr;
}

export function listRegistrationStatus() {
  const { enums } = getBaseDataLocal();
  const list = Object.keys(enums?.student_registration_status);

  let _arr = [];
  list.forEach((v) => {
    switch (Number(v)) {
      case STUDENT_REGISTRATION_STATUS.INQUIRY_DROP:
        _arr.push({ value: STUDENT_REGISTRATION_STATUS.INQUIRY_DROP, label: "Inquiry drop" });
        break;
      case STUDENT_REGISTRATION_STATUS.PENDING:
        _arr.push({ value: STUDENT_REGISTRATION_STATUS.PENDING, label: "Pending" });
        break;
      case STUDENT_REGISTRATION_STATUS.PRE_CAMP:
        _arr.push({ value: STUDENT_REGISTRATION_STATUS.PRE_CAMP, label: "Pre-camp" });
        break;
      case STUDENT_REGISTRATION_STATUS.WAITING_FOR_VISIT:
        _arr.push({
          value: STUDENT_REGISTRATION_STATUS.WAITING_FOR_VISIT,
          label: "Waiting for visit",
        });
        break;
      case STUDENT_REGISTRATION_STATUS.CONTRACT:
        _arr.push({ value: STUDENT_REGISTRATION_STATUS.CONTRACT, label: "Contract" });
        break;
      default:
        break;
    }
  });
  return _arr;
}
