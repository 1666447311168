import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { notification } from "antd"
import { getCookieAuth, removeCookies } from "lib/cookie"
import { getCurrentTimezone } from "lib/function"
import i18n from "i18next"

const refreshAuthLogic = (failedRequest) =>
  axios
    .post(`${process.env.REACT_APP_ID_URL}/api/v1/account/refresh/`, null, {
      headers: {
        access_token: getCookieAuth()?.access_token
      }
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem("access_token", tokenRefreshResponse.data.token)

      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.token
      return Promise.resolve()
    })
    .catch(() => {
      localStorage.clear()
      removeCookies()
      // axios.delete("/auth/register", { token: getRefreshToken() })
      window.location.href = "/login"
    })

export default function getInstanceAxios(baseAPI, method, isHideMessage) {
  const instance = axios.create({
    // withCredentials: true,
    baseURL: baseAPI
  })

  instance.interceptors.request.use(
    async function (config) {
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        method: method,
        Timezone: getCurrentTimezone(),
        Location: localStorage.getItem("location"),
        "Tenant-Header": process.env.REACT_APP_TENANT_HEADER,
        "Accept-Language": i18n.language,
        Language: i18n.language
      }
      const { access_token } = getCookieAuth()
      if (access_token) headers["Authorization"] = `Bearer ${access_token}`
      config.headers = headers
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      try {
        if (response.status >= 200 && response.status < 300) return response.data
        return Promise.reject(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async function (error) {
      if (error.response) {
        const { response } = error
        if (response.status === 401) removeCookies()
        const data = response.data
        if (data && response.config.method !== "get" && response.status !== 500 && !isHideMessage) {
          if (data.error_code === "CLIENT_REACHED_MAX_BRAND") return Promise.reject(data)
          if (data.error_message) {
            notification.error({
              description: convertMessageError(data.error_message, i18n.t),
              duration: 3
            })
            return Promise.reject(data.error_message)
          }
          if (data.message) {
            notification.error({
              description: i18n.t(data?.message) || i18n.t(data?.error_message)
            })
            return
          }
          if (data && !data.message && Object.values(data).length > 0) {
            if (data.length > 0) {
              data.forEach((item) => {
                notification.error({
                  description: i18n.t(item),
                  duration: 3
                })
              })
            } else {
              Object.values(data).forEach((item) => {
                notification.error({
                  description: item?.length > 0 ? i18n.t(item[0]) : i18n.t("N/A"),
                  duration: 3
                })
              })
            }
          } else {
            notification.error({
              description: i18n.t(data?.message) || i18n.t(data?.error_message)
            })
          }
        }
      }
      if (error?.response?.data?.error_code === "ERR_MNT_503") {
        if (window.location.pathname !== "/maintenance") {
          window.location.href = "/maintenance"
        }
      }
      return Promise.reject(error)
    }
  )

  createAuthRefreshInterceptor(instance, refreshAuthLogic)
  return instance
}

export function convertMessageError(errors, t = (text) => text) {
  var str = []
  for (let i = 0; i < errors.length; i++) {
    if (typeof errors[i] === "string") {
      str.push(`${t(errors[i])}. `)
    } else {
      let k = Object.keys(errors[i])[0]
      str.push(k + " : " + t(errors[i][k]))
    }
  }
  return str.map((i) => {
    return <div>{i}</div>
  })
}
