import { RESOURCE_KEY } from "lib/Const"
import { lazy } from "react"

const Dashboard = lazy(() => import("pages/Dashboard"))
const ReferralBonuses = lazy(() => import("pages/ReferralBonuses"))
const UserProfile = lazy(() => import("pages/UserProfile"))
const UserList = lazy(() => import("pages/UserManagement/UserList"))
const UpdateUser = lazy(() => import("pages/UserManagement/UserList/UpdateUser"))
const ApprovalList = lazy(() => import("pages/ApprovalList"))
const ActivityLog = lazy(() => import("pages/ActivityLog"))
const SendNotification = lazy(() => import("pages/SendNotification"))
const Segment = lazy(() => import("pages/Segment"))
const MyNotification = lazy(() => import("pages/MyNotification"))
const School = lazy(() => import("pages/School"))
const UpdateSchool = lazy(() => import("pages/School/UpdateSchool"))
const DetailSchool = lazy(() => import("pages/School/DetailSchool"))
const Region = lazy(() => import("pages/Region"))
const Area = lazy(() => import("pages/Area"))
const Bank = lazy(() => import("pages/BankTabs"))
const ExerciseBook = lazy(() => import("pages/ExerciseBook"))
const TrainingCourse = lazy(() => import("pages/TrainingCourse"))
const DiscountSetting = lazy(() => import("pages/TrainingCourse/DiscountSetting"))
const ContractCourse = lazy(() => import("pages/ContractCourse"))
const UpdateContractCourse = lazy(() => import("pages/ContractCourse/UpdateContractCourse"))
const DetailContractCourse = lazy(() => import("pages/ContractCourse/DetailContractCourse"))
const StudentSubject = lazy(() => import("pages/StudentSubject"))
const OfficialSchool = lazy(() => import("pages/OfficialSchool"))
const SchoolEvent = lazy(() => import("pages/OfficialSchool/SchoolEvent"))
const ReasonWithdrawal = lazy(() => import("pages/ReasonWithdrawal"))
const StudentList = lazy(() => import("pages/StudentList"))
const StudentDetail = lazy(() => import("pages/StudentDetail"))
const UpdateStudent = lazy(() => import("pages/StudentList/UpdateStudent"))
const RegistrationList = lazy(() => import("pages/RegistrationList"))
const UpdateRegistrationList = lazy(() => import("pages/RegistrationList/UpdateRegistrationList"))
const SeasonalInterview = lazy(() => import("pages/SeasonalInterview"))
const CorrespondenceHistory = lazy(() => import("pages/CorrespondenceHistory"))
const ShortTermList = lazy(() => import("pages/ShortTermList"))
const WithdrawalList = lazy(() => import("pages/WithdrawalList"))
const TeacherList = lazy(() => import("pages/TeacherList"))
const TeacherDetail = lazy(() => import("pages/TeacherDetail"))
const UpdateTeacher = lazy(() => import("pages/TeacherList/UpdateTeacher"))
const CalendarClass = lazy(() => import("pages/ClassPage/CalendarClass"))
const CalendarSeatingChart = lazy(() => import("pages/ClassPage/CalendarSeatingChart"))
const ClassSeatingChart = lazy(() => import("pages/ClassPage/ClassSeatingChart"))
const RescheduledClasses = lazy(() => import("pages/RescheduledClasses"))
const TeacherShiftRequest = lazy(() => import("pages/ClassPage/TeacherShiftRequest"))
const TacherShiftRequestDetail = lazy(
  () => import("pages/ClassPage/TeacherShiftRequest/TacherShiftRequestDetail")
)

const AutomaticAssignment = lazy(() => import("pages/AutomaticAssignment"))
const ShiftDetermination = lazy(() => import("pages/ShiftDetermination"))
const ExportingStudentSchedule = lazy(() => import("pages/ExportingStudentSchedule"))
const ScheduleConfirmation = lazy(() => import("pages/ScheduleConfirmation"))
const BillingClosingProcess = lazy(() => import("pages/BillingClosingProcess"))
const BillingClosingProcessDetail = lazy(
  () => import("pages/BillingClosingProcess/BillingClosingProcessWithSchool")
)
const BillingClosingProcessConfirm = lazy(
  () => import("pages/BillingClosingProcess/BillingClosingProcessConfirm")
)
// const BillingClosingDetail = lazy(() => import("pages/BillingClosingDetail"))
const BillingList = lazy(() => import("pages/BillingList"))
const UnpaidList = lazy(() => import("pages/UnpaidList"))
const SaleList = lazy(() => import("pages/SaleList"))
const EducationMaterials = lazy(() => import("pages/SaleList/EducationMaterials"))
const EditTeacherClass = lazy(() => import("pages/TeacherDetail/Class/EditTeacherClass"))

const SchoolLevel = lazy(() => import("pages/SchoolLevel"))
const SchoolYear = lazy(() => import("pages/SchoolYear"))
// const Totalization = lazy(() => import("pages/Totalization"))
// const ListBySchool = lazy(() => import("pages/ListBySchool"))
// const ListByTeacher = lazy(() => import("pages/ListByTeacher"))
const SalaryClosingProcess = lazy(() => import("pages/SalaryClosingProcess"))
const SalaryClosingDetail = lazy(() => import("pages/SalaryClosingProcess/SalaryClosingDetail"))
const SalariesList = lazy(() => import("pages/SalariesList"))
const SalaryIncreaseHistory = lazy(() => import("pages/SalaryIncreaseHistory"))
const GeneralReport = lazy(() => import("pages/GeneralReport"))
const ContractUnitPriceReport = lazy(() => import("pages/ContractUnitPriceReport"))
const SeasonalTrainingReport = lazy(() => import("pages/SeasonalTrainingReport"))
const TimedCourseReport = lazy(() => import("pages/TimedCourseReport"))
const TimedCourseReportDetail = lazy(
  () => import("pages/TimedCourseReport/TimedCourseReportDetail")
)
const Privileges = lazy(() => import("pages/Privileges"))
const DiscountPrivileges = lazy(() => import("pages/DiscountPrivileges"))
const BillingExpenseItem = lazy(() => import("pages/BillingExpenseItem"))
const RaiseWageRules = lazy(() => import("pages/RaiseWageRules"))
const TeacherBaseWage = lazy(() => import("pages/TeacherBaseWage"))
const AdMedia = lazy(() => import("pages/AdMedia"))
const CSVImportHistory = lazy(() => import("pages/CSVImportHistory"))
const Exams = lazy(() => import("pages/Exams"))
const CreateExamScore = lazy(() => import("pages/CreateExamScore"))
const ExamResults = lazy(() => import("pages/ExamResults"))
const TestExam = lazy(() => import("pages/TestExam"))
const SeminarParticipantList = lazy(() => import("pages/SeminarParticipantList"))
const VideoClassRequestList = lazy(() => import("pages/VideoClassRequestList"))
const AdMediaMonthlyAggregation = lazy(() => import("pages/AdMediaMonthlyAggregation"))
const StudentSalesStatusReport = lazy(() => import("pages/StudentSalesStatusReport"))
const NotificationManagement = lazy(() => import("pages/Notification"))
const UpdateNotification = lazy(() => import("pages/Notification/UpdateNotification"))
const TeachingReport = lazy(() => import("pages/TeachingReport"))
const TeachingReportDetail = lazy(() => import("pages/TeachingReport/TeachingReportDetail"))
const CreateReportTeachingByStudent = lazy(
  () => import("pages/TeachingReport/CreateReportTeachingByStudent")
)
const CreateReportTeachingByLesson = lazy(
  () => import("pages/TeachingReport/CreateReportTeachingByLesson")
)
const TeachingReportSetting = lazy(() => import("pages/TeachingReport/TeachingReportSetting"))
const NotificationSetting = lazy(() => import("pages/MyNotification/NotificationSetting"))
const CreateTemplateNotification = lazy(
  () => import("pages/MyNotification/CreateTemplateNotification")
)
const TargetSetting = lazy(() => import("pages/SettingCommunication/TargetSetting"))
// const ChatSetting = lazy(() => import("pages/Chat/ChatSetting"))
const ChatList = lazy(() => import("pages/Chat"))
const ChatDetail = lazy(() => import("pages/Chat/ChatDetail"))
const AttendanceManagement = lazy(() => import("pages/AttendanceManagement"))
const AttendanceSetting = lazy(() => import("pages/AttendanceManagement/AttendanceSetting"))

//____ListPage

function pageList(__role) {
  return [
    {
      path: "/",
      Element: Dashboard,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "dashboard",
      pageName: "Dashboard",
      Element: Dashboard,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "dashboard/referral-bonuses",
      pageName: "ReferralBonuses",
      Element: ReferralBonuses,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/profile",
      Element: UserProfile,
      resource: RESOURCE_KEY.USER_PROFILE
    },
    {
      path: "/setting/organization/user-list",
      Element: UserList,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/setting/organization/user-list/action",
      Element: UpdateUser,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/approval",
      Element: ApprovalList,
      resource: RESOURCE_KEY.APPROVAL
    },
    {
      path: "activity-logs",
      Element: ActivityLog,
      resource: RESOURCE_KEY.ACTIVITY_LOG
    },
    {
      path: "/notification/send-notification",
      Element: SendNotification,
      resource: RESOURCE_KEY.NOTIFICATION_LIST
    },
    {
      path: "/notification/segment",
      Element: Segment,
      resource: RESOURCE_KEY.NOTIFICATION_SEMENT
    },
    {
      path: "/my-notification",
      Element: MyNotification,
      resource: RESOURCE_KEY.MY_NOTIFICATION
    },
    {
      path: "/student/list",
      Element: StudentList,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/student/list/view",
      Element: StudentDetail,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/student/list/action",
      Element: UpdateStudent,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/student/registration",
      Element: RegistrationList,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/student/registration/action",
      Element: UpdateRegistrationList,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/student/seasonal-interview",
      Element: SeasonalInterview,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/student/correspondence-history",
      Element: CorrespondenceHistory,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/student/short-term-list",
      Element: ShortTermList,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/student/withdrawal-list",
      Element: WithdrawalList,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/teacher/list",
      Element: TeacherList,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/teacher/detail",
      Element: TeacherDetail,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/teacher/list/action",
      Element: UpdateTeacher,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/teacher/detail/edit-class",
      Element: EditTeacherClass,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "classroom",
      Element: School,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "classroom/action",
      Element: UpdateSchool,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "classroom/detail",
      Element: DetailSchool,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/organization/region",
      Element: Region,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/organization/area",
      Element: Area,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/organization/bank",
      Element: Bank,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/organization/school/list",
      Element: School,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/organization/school/list/action",
      Element: UpdateSchool,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/organization/school/list/detail",
      Element: DetailSchool,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/csv-import-history",
      Element: CSVImportHistory,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/exercise-book",
      Element: ExerciseBook,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/training-course",
      Element: TrainingCourse,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/training-course/discount-setting",
      Element: DiscountSetting,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/regular-course",
      Element: ContractCourse,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/regular-course/action",
      Element: UpdateContractCourse,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/regular-course/detail",
      Element: DetailContractCourse,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/subject",
      Element: StudentSubject,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/official-school",
      Element: OfficialSchool,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/official-school/school-events",
      Element: SchoolEvent,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/reason-for-withdrawal",
      Element: ReasonWithdrawal,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/privileges",
      Element: Privileges,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/discount-privileges",
      Element: DiscountPrivileges,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/student/billing-expense-item",
      Element: BillingExpenseItem,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/teacher/raise-wage-rules",
      Element: RaiseWageRules,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/teacher/teacher-base-wage",
      Element: TeacherBaseWage,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/setting/report/ad-media",
      Element: AdMedia,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/class/calendar",
      Element: CalendarClass,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/class/calendar-week",
      Element: CalendarSeatingChart,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/class/calendar-week/seating-chart",
      Element: ClassSeatingChart,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/class/rescheduled-classes",
      Element: RescheduledClasses,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/class/teacher-shift-request",
      Element: TeacherShiftRequest,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/class/teacher-shift-request/detail",
      Element: TacherShiftRequestDetail,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/class/automatic-seat-asignment",
      Element: AutomaticAssignment,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/class/shift-determination-notification",
      Element: ShiftDetermination,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/class/exporting-student-schedule",
      Element: ExportingStudentSchedule,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/class/schedule-confirmation",
      Element: ScheduleConfirmation,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/billing/billing-closing-process",
      Element: BillingClosingProcess,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/billing/billing-closing-process/detail",
      Element: BillingClosingProcessDetail,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/billing/billing-closing-process/confirm",
      Element: BillingClosingProcessConfirm,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/billing/billing-list",
      Element: BillingList,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/billing/unpaid-list",
      Element: UnpaidList,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/billing/sale-list",
      Element: SaleList,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/billing/sale-list/education-materials",
      Element: EducationMaterials,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/billing/test-exam",
      Element: TestExam,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/billing/test-exam/seminar-participant-list",
      Element: SeminarParticipantList,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/billing/video-class-request-list",
      Element: VideoClassRequestList,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "school/school-level",
      Element: SchoolLevel,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "school/school-year",
      Element: SchoolYear,
      resource: RESOURCE_KEY.DASHBOARD
    },
    // {
    //   path: "/salary/monthly-salary/totalization",
    //   Element: Totalization,
    //   resource: RESOURCE_KEY.DASHBOARD
    // },
    // {
    //   path: "/salary/monthly-salary/list-by-school",
    //   Element: ListBySchool,
    //   resource: RESOURCE_KEY.DASHBOARD
    // },
    // {
    //   path: "/salary/monthly-salary/list-by-teacher",
    //   Element: ListByTeacher,
    //   resource: RESOURCE_KEY.DASHBOARD
    // },
    {
      path: "/salary/salary-closing-process",
      Element: SalaryClosingProcess,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/salary/salary-closing-process/detail",
      Element: SalaryClosingDetail,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/salary/salaries-list",
      Element: SalariesList,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/salary/salary-increase-history",
      Element: SalaryIncreaseHistory,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "report/general-report",
      Element: GeneralReport,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "report/general-report/ad-media-monthly-aggregation",
      Element: AdMediaMonthlyAggregation,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "report/contract-unit-price-report",
      Element: ContractUnitPriceReport,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "report/student-sales-status-report",
      Element: StudentSalesStatusReport,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "report/seasonal-training-report",
      Element: SeasonalTrainingReport,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "report/timed-course-report",
      Element: TimedCourseReport,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "report/timed-course-report/detail",
      Element: TimedCourseReportDetail,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "grade-management/exams",
      Element: Exams,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "grade-management/exams/:id/create-exam-score",
      Element: CreateExamScore,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "grade-management/exams/:id/",
      Element: ExamResults,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "notification-management",
      Element: NotificationManagement,
      resource: RESOURCE_KEY.NOTIFICATION_MANAGEMENT
    },
    {
      path: "notification-management/action",
      Element: UpdateNotification,
      resource: RESOURCE_KEY.NOTIFICATION_MANAGEMENT
    },
    {
      path: "teaching-report",
      Element: TeachingReport,
      resource: RESOURCE_KEY.TEACHING_REPORT
    },
    {
      path: "teaching-report/action-by-student",
      Element: CreateReportTeachingByStudent,
      resource: RESOURCE_KEY.TEACHING_REPORT
    },
    {
      path: "teaching-report/action-by-lesson",
      Element: CreateReportTeachingByLesson,
      resource: RESOURCE_KEY.TEACHING_REPORT
    },
    {
      path: "teaching-report/detail",
      Element: TeachingReportDetail,
      resource: RESOURCE_KEY.TEACHING_REPORT
    },
    {
      path: "/setting/communication/target",
      Element: TargetSetting,
      resource: RESOURCE_KEY.SETTING_COMMUNICATION
    },
    {
      path: "/setting/communication/teaching-report",
      Element: TeachingReportSetting,
      resource: RESOURCE_KEY.SETTING_COMMUNICATION
    },
    {
      path: "/setting/communication/notification",
      Element: NotificationSetting,
      resource: RESOURCE_KEY.SETTING_COMMUNICATION
    },
    {
      path: "/setting/communication/notification/action",
      Element: CreateTemplateNotification,
      resource: RESOURCE_KEY.SETTING_COMMUNICATION
    },
    // {
    //   path: "/setting/communication/chat",
    //   Element: ChatSetting,
    //   resource: RESOURCE_KEY.SETTING_COMMUNICATION
    // },
    {
      path: "/chat",
      Element: ChatList,
      resource: RESOURCE_KEY.CHAT
    },
    {
      path: "/chat/detail",
      Element: ChatDetail,
      resource: RESOURCE_KEY.CHAT
    },
    {
      path: "/attendance-management",
      Element: AttendanceManagement,
      resource: RESOURCE_KEY.ATTENDANCE_MANAGEMENT
    },
    {
      path: "/setting/attendance-setting",
      Element: AttendanceSetting,
      resource: RESOURCE_KEY.ATTENDANCE_SETTING
    }

    //next_component
  ]
}

export default pageList
