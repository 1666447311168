import { Image, Tooltip } from "antd";
import { useState } from "react";
import logoBlue from "assets/images/logoBlue.png";
import backgroundFull from "assets/images/backgroundFull.png";
import LoginForm from "./LoginForm";
import TwoFactorAuthen from "./TwoFactorAuthen";
import OTPCode from "./OTPCode";
import OTPRecoveryCode from "./OTPRecoveryCode";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import { useStore } from "components/ui";

const Main = () => {
  const { settingTheme } = useStore();
  const [step, setStep] = useState(1);
  const [firstLogin, setFirstLogin] = useState(false);
  const [existLogin, setExistLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [authenType, setAuthenType] = useState(0);

  function onNextFirstLogin(email) {
    setAuthenType(1);
    setEmail(email);
    setExistLogin(false);
    setFirstLogin(true);
    setStep(3);
  }

  function onNextToAuthen(email, _user) {
    setEmail(email);
    setUser(_user);
    setFirstLogin(true);
    setExistLogin(true);
    setStep(2);
  }

  function onNextToSpecific2Fa(email, _user, two_fa) {
    setEmail(email);
    setUser(_user);
    setAuthenType(two_fa);
    setFirstLogin(true);
    setExistLogin(true);
    setStep(3);
  }

  function onNextChoiceAuthen(two_fa) {
    // setUser((prev) => ({ ...prev, two_fa: two_fa }))
    setAuthenType(two_fa);
    setStep(3);
  }

  function onNextFillCode(auth, user) {
    setAuth(auth);
    setUser(user);
    setStep(5);
  }

  function onForgot() {
    setAuthenType(1);
    setFirstLogin(false);
    setExistLogin(false);
    setStep(4);
  }

  function onNextForget(email) {
    setEmail(email);
    setStep(3);
  }

  function onNextChangePassword() {
    setStep(1);
  }

  function onDirrectToRecoveryCode() {
    setStep(6);
  }

  function onDirrectToAuthenCode() {
    setStep(3);
  }

  function onBack() {
    setStep(1);
  }

  const name = settingTheme?.name;
  const shouldShowTooltip = name?.length >= 10;

  return (
    <div className="relative __login">
      <div className="flex bg-gray-50 dark:bg-gray-900 flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen min-h-[830px] lg:py-0">
        <div>
          <img
            src={backgroundFull}
            className="img__bg"
            alt="backgroundFull"
            style={{ height: "calc(100vh - 92px)" }}
          />
        </div>
        <div className="absolute top-[6%] w-full bg-white p-6 rounded-3xl shadow dark:border md:mt-0 sm:max-w-[480px] dark:bg-gray-800 dark:border-gray-700">
          <div className="flex justify-center items-center mt-6 gap-4">
            <Image height={80} preview={false} className="rounded" src={settingTheme?.logo} />
            {shouldShowTooltip ? (
              <Tooltip placement="bottom" title={name}>
                <div className="text-5xl text-primary-600 font-medium truncate max-w-xs">
                  {name.slice(0, 10) + "..."}
                </div>
              </Tooltip>
            ) : (
              <div className="text-5xl text-primary-600 font-medium">{name}</div>
            )}
          </div>

          {step === 1 && (
            <LoginForm
              auth={auth}
              user={user}
              onNext={onNextToAuthen}
              onNextFirstLogin={onNextFirstLogin}
              onNextToSpecific2Fa={onNextToSpecific2Fa}
              onForgot={onForgot}
            />
          )}
          {step === 2 && (
            <TwoFactorAuthen
              user={user}
              email={email}
              onNext={onNextChoiceAuthen}
              onBack={onBack}
            />
          )}
          {step === 3 && (
            <OTPCode
              email={email}
              firstLogin={firstLogin}
              existLogin={existLogin}
              user={user}
              authenType={authenType}
              onNext={onNextFillCode}
              onDirrectToRecoveryCode={onDirrectToRecoveryCode}
              onBack={onBack}
            />
          )}
          {step === 4 && <ForgotPassword onNext={onNextForget} onBack={onBack} />}
          {step === 5 && (
            <ChangePassword
              auth={auth}
              user={user}
              firstLogin={firstLogin}
              onNext={onNextChangePassword}
              onBack={onBack}
            />
          )}
          {step === 6 && (
            <OTPRecoveryCode
              email={email}
              onDirrectToAuthenCode={onDirrectToAuthenCode}
              onBack={onBack}
            />
          )}
        </div>
      </div>
      <div className="absolute bottom-0 w-full h-[92px] border-t-[1px] border-[#E6EDF2] bg-[#F8FAFD]">
        <div className="h-full flex justify-center items-center">
          <div className="flex items-center gap-[8px]">
            <div className="text-[16px] text-[#646970] font-[400]">Powered by</div>
            <Image width={135} preview={false} src={logoBlue} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
