import Client from "../client/Client"
import version from "../version"
import { STUDENT_REGISTRATION_STATUS } from "pages/RegistrationList/List"
const resource = version + "/tenant/student"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function getIntervews(params) {
  return Client.get(`${resource}/selection/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/detail/`)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function exportList(params) {
  return Client.post(`${resource}/export-csv/`, { params })
}

function exportListUpdate(params) {
  return Client.post(`${resource}/export-csv/`, params)
}

function getsSelection(params) {
  return Client.get(`${resource}/selection/`, { params })
}

function updateNote(id, data) {
  return Client.put(`${resource}/${id}/note/`, data)
}

function getNote(id) {
  return Client.get(`${resource}/${id}/note/`)
}

function getBankAccount(id) {
  return Client.get(`${resource}/${id}/bank-account/`)
}

function updateBankAccount(id, data) {
  return Client.put(`${resource}/${id}/bank-account/`, data)
}

function getsSibling(params) {
  return Client.get(`${resource}/sibling/`, { params })
}

function updateSibling(id, siblingId, data) {
  return Client.put(`${resource}/${id}/sibling/${siblingId}/`, data)
}

function removeSibling(id, siblingId) {
  return Client.delete(`${resource}/${id}/sibling/${siblingId}/`)
}

function reRegister(id, data) {
  return Client.put(`${resource}/${id}/re-enrolled/`, data)
}

function removeTrialDate(id, data) {
  return Client.put(`${resource}/${id}/remove-trial/`, data)
}
function createInterView(data) {
  return Client.post(`${resource}/correspondence-history/`, data)
}
function updateInterView(id, data) {
  return Client.put(`${resource}/correspondence-history/${id}/`, data)
}

function cancelInterview(id, data) {
  return Client.put(
    `${resource}/correspondence-history/${id}/?is_cancel=${STUDENT_REGISTRATION_STATUS.PRE_CAMP}`,
    data
  )
}

function resetStudentPassword(id, data) {
  return Client.put(`${resource}/${id}/reset-password/`, data)
}
function resetParentPassword(id, data) {
  return Client.put(`${resource}/${id}/reset-parent-password/`, data)
}

const api = {
  gets,
  getIntervews,
  get,
  create,
  update,
  remove,
  exportList,
  updateNote,
  getNote,
  getBankAccount,
  updateBankAccount,
  getsSelection,
  getsSibling,
  updateSibling,
  removeSibling,
  reRegister,
  removeTrialDate,
  createInterView,
  updateInterView,
  resetStudentPassword,
  resetParentPassword,
  cancelInterview,
  exportListUpdate
}
export default api
