import { Empty, Image, Table } from "antd"
import { useEffect } from "react"
import { useState } from "react"
import "./index.scss"
// import EmptyBoxAll from "assets/images/EmptyBox.png"
import { useTranslation } from "react-i18next"
import iconNotFound from "assets/images/emptyIcon-glass.png"
function getWidth(list) {
  let total = 0
  for (const item of list) {
    if (item.width) total += item.width
    if (item.minWidth) total += item.minWidth
  }
  return total
}

export default function MyTable({
  columns,
  x,
  y,
  notScrollY = false,
  rowKey = "id",
  yHead = 340,
  onChange,
  descriptionNoData = "",
  className = "",
  titleNoData = "",
  iconNotFound,
  scrollX,
  rowSelection = null,
  ...rest
}) {
  const [scroll, setScroll] = useState()

  function handleTableChange(newPagination, filters, sorter) {
    if (onChange) onChange(sorter)
  }

  useEffect(() => {
    const scrollY = window.innerHeight - yHead
    const __scroll = { x: getWidth(columns), y: notScrollY ? undefined : y || scrollY }
    setScroll(__scroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`rounded-xl shadow-sm overflow-hidden border ${className}`}>
      <Table
        size="small"
        // bordered
        rowKey={(r) => r[rowKey]}
        pagination={false}
        scroll={scrollX || scroll}
        columns={columns}
        onChange={handleTableChange}
        rowClassName={(_, index) => (index % 2 === 0 ? "even-row" : "odd-row")}
        rowSelection={rowSelection ? { ...rowSelection, preserveSelectedRowKeys: true } : null}
        locale={{
          emptyText: (
            <EmptyBox
              description={descriptionNoData}
              titleNoData={titleNoData}
              // iconNotFound={iconNotFound}
            />
          )
        }}
        {...rest}
      />
    </div>
  )
}

const EmptyBox = ({ description, titleNoData }) => {
  const { t } = useTranslation()
  return (
    <Empty
      imageStyle={{ height: "100%" }}
      image={
        <div>
          <Image
            width={200}
            height={200}
            preview={false}
            // src={iconNotFound ? iconNotFound : EmptyBoxAll}
            src={iconNotFound}
          />
        </div>
      }
      description={
        <span className="text-dark-500 text-base">{titleNoData || t("Data not found")}</span>
      }
    >
      <span className="text-dark-400 text-base">{description}</span>
    </Empty>
  )
}
