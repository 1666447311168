import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/student/registration"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function getListExperiences(params) {
  return Client.get(`${version}/tenant/calendar/student-schedules/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function exportList(data) {
  return Client.post(`${resource}/export-csv/`, data)
}
function exportListExcel(data, options = {}) {
  return Client.post(`${resource}/export-excel/`, data, {
    ...options,
    responseType: "blob"
  })
}
const api = {
  gets,
  get,
  getListExperiences,
  create,
  update,
  remove,
  exportList,
  exportListExcel
}
export default api
