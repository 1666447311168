import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/student/seasonal-interview"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function exportList(data, options = {}) {
  return Client.post(`${resource}/export-excel`, data, {
    ...options,
    responseType: "blob"
  })
}
function createInterview(id, data) {
  return Client.post(`${resource}/${id}/correspondence-history/`, data)
}
function updateInterview(id, data) {
  return Client.put(`${resource}/correspondence-history/${id}/`, data)
}
function removeInterview(id) {
  return Client.delete(`${resource}/correspondence-history/${id}/`)
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  exportList,
  createInterview,
  updateInterview,
  removeInterview
}
export default api
