import { isValidPhoneNumber } from "react-phone-number-input"
import { isIP, isIPv4 } from "is-ip"

export function isEmailValid(value) {
  if (/^[a-z0-9]+(?:[._+-][a-z0-9]+)*@[a-z0-9-]+(?:\.[a-z0-9-]+)*\.[a-z]+$/i.test(value)) {
    return false
  }
  return true
}

export function onlyWhiteSpace(s) {
  return s.trim().length === 0
}

export const validateKana = (value, t) => {
  if (value) {
    const _value = value.trim()
    const regexKanaFullWidth = /^[ァ-ンー・\s]+$/
    const regexHiraganaFullWidth = /^[ぁ-んー・\s]+$/
    if (!regexKanaFullWidth.test(_value) && !regexHiraganaFullWidth.test(_value)) {
      return Promise.reject(new Error(t("Only Katakana and Hiragana characters can be entered.")))
    }
    if (value.length > 200) return Promise.reject(t("Value must not exceed 200 characters."))
    if (onlyWhiteSpace(value))
      return Promise.reject(t("Value should not consist only of whitespace"))
  }
  return Promise.resolve()
}

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

export function isPrice(value) {
  const numberPart = value.toString().split(".")[0]
  if (numberPart && numberPart.length > 10) {
    return false
  }
  return true
}

export function isDecimal(value) {
  const decimalPart = value.toString().split(".")[1]
  if (decimalPart && decimalPart.length > 2) {
    return false
  }
  return true
}

export function isPhoneValid(phone) {
  if (phone && isValidPhoneNumber(phone)) {
    return true // Valid phone number
  }
  return false // Invalid phone number
}

export function isPostcodeValid(value) {
  var regPostcodeUS = /^\d{5}(-\d{4})?$/
  var regexPostcodeUK = /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/i
  var regexPostcodeJP = /^\d{3}-\d{4}$/
  var regexPostcodeVN = /^\d{6}$/
  if (
    regPostcodeUS.test(value) ||
    regexPostcodeUK.test(value) ||
    regexPostcodeJP.test(value) ||
    regexPostcodeVN.test(value)
  ) {
    return true
  }
  return false
}

export function hasWhiteSpace(s) {
  return s.indexOf(" ") >= 0
}

export function isValidHttpUrl(string) {
  const regex = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/
  return regex.test(string)
}

export function isValidateIP(ip) {
  return isIP(ip) || isIPv4(ip) // Valid if it's a valid IP address or IPv4 address
}

export function isValidateIPRange(from, to) {
  const ipToNumber = (ip) =>
    ip.split(".").reduce((acc, octet, index) => acc + parseInt(octet) * Math.pow(256, 3 - index), 0)

  const fromIPNumber = ipToNumber(from)
  const toIPNumber = ipToNumber(to)

  return toIPNumber >= fromIPNumber
}

export function isSomeSpecialCharacterValid(value) {
  // eslint-disable-next-line no-useless-escape
  if (/^[!@#$%^&*()_+]+$/.test(value)) {
    return true
  }
  return false
}

export function isSpecialCharacterValid(value) {
  // eslint-disable-next-line no-useless-escape
  if (/^[\p{L}\p{N}\p{Zs}]+$/u.test(value)) {
    return false
  }
  return true
}

export function isOnlyNumbers(string) {
  const regex = /^\d+$/
  return regex.test(string)
}

export function convertToOneByteCharacters(value, typingState = [false, () => {}]) {
  if (!value) return value

  const [isTyping, setIsTyping] = typingState
  if (isTyping) {
    setIsTyping(false)
    return value.slice(0, -1)
  } else {
    return value
      .replace(/[\uff01-\uff5e]/g, (char) => String.fromCharCode(char.charCodeAt(0) - 0xfee0))
      .replace(/\u3000/g, " ")
      .replace(/，/g, ",")
      .replace(/．/g, ".")
      .replace(/－/g, "-")
      .normalize("NFKC")
  }
}

export function validateTwoLineMaxLength(_, value, t) {
  // if (value && value.trim().length === 0) {
  //   return Promise.reject(t("Value should not consist only of whitespace"));
  // }

  if (value) {
    const lines = value.replace(/\r/g, "").split("\n")

    if (value.split("\n").join("").trim().length > 97) {
      return Promise.reject(t("Value must not exceed 96 characters (2 lines x 48 chars)."))
    }

    if (lines.length > 2) {
      return Promise.reject(t("You can only enter up to 2 lines."))
    }

    if (lines.some((line, index) => index < 2 && line.length > 48)) {
      return Promise.reject(t("Each line must not exceed 48 characters."))
    }

    return Promise.resolve()
  }
}
